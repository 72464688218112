<template>
  <MainTemplate id="achievementsView" bg="assets/img/bgs/2.jpg" :h1="this.$t('achievments.h1')"
    :p="this.$t('achievments.p')" :span="this.$t('achievments.span')">
    <section>
      <img :src="`${require(`@/assets/img/achievements/keys-${$i18n.locale}.svg`)}`" alt="keys image" />
    </section>
  </MainTemplate>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
export default {
  name: "AchievementsView",
  components: { MainTemplate },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";

section {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media all and (max-width: $mobile) {
  section {
    img {
      width: 95%;
      transform: initial;
    }
  }
}
</style>