<template>
  <SubTemplate
    v-if="selected"
    :invalid="invalid"
    :name="$t(`news.news-${selected}.name`)"
    :desc="$t(`news.news-${selected}.desc`)"
    :image="`news/${selected}.jpg`"
    cover="projects/project-1/3.jpg"
  >
    <template v-for="(m, i) in $t(`news.news-${selected}.article`)">
      <p v-if="m.title" v-html="m.title" :key="`title-article-${i}`"></p>
      <span v-if="m.txt" v-html="m.txt" :key="`txt-article-${i}`"></span>
    </template>

    <h1 v-html="$t(`news.news-${selected}.source`)"></h1>
  </SubTemplate>
</template>

<script>
import SubTemplate from "@/components/SubTemplate";
export default {
  components: { SubTemplate },
  name: "TheSelectedNews",
  data() {
    return {
      selected: "",
      invalid: false,
    };
  },
  mounted() {
    this.selected = this.$route.params.news;
    if (this.selected > 3) this.invalid = true;

    if(this.$i18n.locale !== 'bg') {
      this.$snotify.error(this.$t('no-english-news'));
      this.$router.push(`/`);
    }
  },
};
</script>