<template>
  <main class="modal-wrapper">
    <transition name="slide-bottom" appear mode="out-in">
      <div class="modal">
        <img @click="close" class="close" src="@/assets/img/close.svg" alt="" />

        <main class="modal-content">
          <aside :style="{
            background: `url(${require(`@/assets/img/services/${card}.jpg`)})`,
          }"></aside>
          <section>
            <header>
              <div>
                <p>{{ $t(`services.card-${card}.p`) }}</p>
                <span>Структурното окабеляване е незаменима част от създаването на
                  добре работеща и ефективна информационна инфраструктура във
                  всяко съвременно предприятие, организация или офис, които са в
                  една сграда или в група от сгради.</span>
                <!-- <span>{{ $t(`services.card-${card.index + 1}.span`) }}</span> -->
              </div>

              <div class="partners-wrapper">
                <p>СВЪРЗАНИ ПАРТНЬОРСКИ ПРОДУКТИ</p>
                <div class="partners">
                  <!-- <img src="@/assets/img/partners/partner-logo-1.png" alt="" />
                  <img src="@/assets/img/partners/partner-logo-2.png" alt="" />
                  <img src="@/assets/img/partners/partner-logo-3.png" alt="" /> -->
                </div>
              </div>
            </header>

            <main>
              <section class="products-wrapper">
                <div class="products">
                  <div class="product" v-for="(p, i) in products" :key="p.name">
                    <img :src="
                      require(`@/assets/img/partners/product-${i + 1}.png`)
                    " alt="" />
                    <p>
                      {{ p.name }}
                    </p>
                  </div>
                </div>
                <Button class="button--big" mainText="Направете запитване" span="за тази услуга" />
              </section>
            </main>
          </section>
        </main>
      </div>
    </transition>
  </main>
</template>
<script>
import Button from "@/components/Button";
export default {
  name: "ServicesModal",
  props: ["card"],
  components: {
    Button,
  },
  data() {
    return {
      products: [
        { name: "Eaton EX RT 3:1 UPS" },
        { name: "Blade UPS (12-60 kW)" },
        { name: "NETGEAR-GSM" },
        { name: "MasterPact MTZ" },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    document.body.classList.add("noscroll");
  },
  destroyed() {
    document.body.classList.remove("noscroll");
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/base.scss";

.modal-wrapper {
  align-items: flex-end;
  background-color: rgba($color: #000, $alpha: 0.4);
  display: flex;
  justify-content: center;
  left: 0;
  height: 100%;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.modal {
  background: #cbd1dd;
  box-shadow: 0px -45px 99px #041f5e;
  color: #373737;
  height: 80%;
  width: 100%;
  position: relative;

  .close {
    background: $mainColor;
    display: block;
    border-radius: 50%;
    padding: 5px;
    max-width: 60px;
    margin-top: -30px;
    max-height: 60px;
    position: absolute;
    right: 2%;
    @include hover();
  }

  .modal-content {
    display: flex;
    height: 100%;
    overflow: auto;

    aside {
      background-position: 20% 0 !important;
      background-size: cover !important;
      border: 12px solid #ffffff;
      width: 23%;
      @include tr();

      &:hover {
        background-position: 40% 0 !important;
      }
    }

    section {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      header {
        background: url("../assets/img/header-bg.png") no-repeat;
        background-size: 230%;
        background-position: right 15% bottom 40%;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        padding: 0 7% 0 1rem;

        p {
          font-size: 36px;
          font-weight: bold;
        }

        span {
          font-size: 26px;
          font-weight: 100;
        }

        .partners-wrapper {
          align-items: center;
          display: flex;
          justify-content: space-between;

          p {
            font-size: 16px;
          }

          img {
            mix-blend-mode: multiply;
            margin: 0 5px;
            object-fit: scale-down;

            &:not(:last-child) {
              border-right: 2px solid #ccd1dd;
              padding-right: 5px;
            }
          }
        }
      }

      main {
        align-items: center;
        display: flex;
        flex-grow: 2;
        justify-content: center;

        .products-wrapper {
          overflow: auto;
          width: fit-content;

          .products {
            display: flex;
            gap: 40px;
            overflow: auto;
            padding: 50px 20px 20px;

            .product {
              padding: 1rem;
              background-color: rgb(255, 255, 255);
              border-radius: 13px;
              backdrop-filter: blur(30px);
              font-weight: bold;
              text-align: center;
              -webkit-backdrop-filter: blur(30px);

              img {
                mix-blend-mode: multiply;
                object-fit: scale-down;
                opacity: 0.95;
                margin-right: -30%;
                margin-top: -20%;
                @include transform(scale(1.03));
              }
            }
          }

          button {
            align-self: flex-end;
          }
        }
      }
    }
  }
}

@media all and (max-width: $mobile) {
  .modal-wrapper {
    .modal {
      height: 95%;

      .modal-content {
        flex-direction: column;

        aside {
          border-radius: 0 0 10px 10px;
          min-height: 25%;
          width: 100%;
        }

        header {
          background-size: 1000%;
          background-position: center;
          padding: 10px;
          margin-bottom: 1rem;
          gap: 1rem;

          p {
            font-size: 29px;
            line-height: 29px;
          }

          span {
            font-size: 18px;
          }

          .partners-wrapper {
            flex-direction: column;
            justify-content: center;

            p {
              font-size: 16px;
            }

            img {
              width: 30%;
            }
          }
        }

        main {
          .products-wrapper {
            button {
              align-self: center;
            }
          }
        }
      }
    }
  }
}
</style>
