<template>
  <MainTemplate
    id="aboutView"
    bg="assets/img/bgs/4.jpg"
    :h1="this.$t('about.h1')"
    :p="this.$t('about.p')"
    :span="this.$t('about.span')"
  >
    <section>    
      <img  :src="`${require(`@/assets/img/about/years+text-${$i18n.locale}.svg`)}`" alt="years image" />

      <div class="links">
        <router-link to="/mission">
          <Button
            type="white"
            :mainText="$t('buttons.about.mission.main')"
            :span="$t('buttons.about.mission.span')"
          />
        </router-link>

        <router-link to="/policy">
          <Button
            type="white"
            :mainText="$t('buttons.about.policy.main')"
            :span="$t('buttons.about.policy.span')"
          />
        </router-link>
        <router-link to="/certificates">
          <Button
            type="white"
            :mainText="$t('buttons.about.certificates.main')"
            :span="$t('buttons.about.certificates.span')"
          />
        </router-link>
        <router-link to="/management">
          <Button type="white" 
          :mainText="$t('buttons.about.management.main')"
          :span="$t('buttons.about.management.span')"
           />
        </router-link>
      </div>
    </section>
  </MainTemplate>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
import Button from "@/components/Button";
export default {
  name: "AboutUs",
  components: { MainTemplate, Button },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";
section {
  align-items: center;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: center;
  gap: 5%;

  .links {
    display: flex;
  }
}
@media all and (max-width: $mobile) {
  section {
    gap: 3rem;
    img {
      width: 90%;
    }

    .links {
      flex-direction: column;
      gap: 10px;

      a {
        max-height: 50px;
      }
    }
  }
}
</style>