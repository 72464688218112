<template>
  <main>
    <MainTemplate
      id="contactsView"
      bg="assets/img/bgs/0.jpg"
      :h1="this.$t('contacts.h1')"
      :p="this.$t('contacts.p')"
      :span="this.$t('contacts.span')"
      @visible="checkIfServiceBtnIsClicked"
    >
      <section class="content-wrapper">
        <section class="content">
          <div class="form-wrapper">
            <FormModal
              v-if="!isMobile"
              :isMobileMode="isMobile"
              @close="isFormModalOpen = false"
            />
            <Button
              v-if="isMobile"
              @click="isFormModalOpen = true"
              type="white"
              :mainText="$t('buttons.contacts.main')"
              :span="$t('buttons.contacts.span')"
            />

            <div class="map mobile">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1266.9197228068883!2d23.362747021154778!3d42.698047726305155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1z0YPQuy4g0JfQu9Cw0YLQvtGB0YLRgNGD0LkgMTjQkSwg0LDQvy4gOC0x!5e0!3m2!1sen!2sbg!4v1683202429495!5m2!1sen!2sbg"
                width="600"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <ContactLinks />
          </div>

          <div class="map desktop">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1266.9197228068883!2d23.362747021154778!3d42.698047726305155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1z0YPQuy4g0JfQu9Cw0YLQvtGB0YLRgNGD0LkgMTjQkSwg0LDQvy4gOC0x!5e0!3m2!1sen!2sbg!4v1683202429495!5m2!1sen!2sbg"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </section>
    </MainTemplate>
    <FormModal
      :isMobileMode="true"
      v-if="isFormModalOpen"
      @close="isFormModalOpen = false"
    />
  </main>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
import FormModal from "@/components/FormModal";
import Button from "@/components/Button";
import ContactLinks from "@/components/ContactLinks";
export default {
  name: "ContactsView",
  components: {
    MainTemplate,
    FormModal,
    Button,
    ContactLinks,
  },
  data() {
    return {
      isMobile: false,
      isFormModalOpen: false,
    };
  },
  methods: {
    // opens the formModal if service button is clicked in mobile
    checkIfServiceBtnIsClicked() {
      let isServiceBtnClicked = localStorage.getItem("to-contacts");
      localStorage.removeItem("to-contacts");
      if (this.isMobile && isServiceBtnClicked) this.isFormModalOpen = true;
    },
    handleResize() {
      if (window.innerWidth < 1365) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";
.content-wrapper {
  .content {
    display: flex;
    flex-grow: 2;
    justify-content: center;
    gap: 10%;

    .form-wrapper {
      display: flex;
      gap: 3rem;
      flex-direction: column;
      max-width: 750px;
      width: 100%;

      button {
        align-self: center;
      }
      .links {
        transform: translateX(35%);
        > a {
          &:nth-child(2) {
            position: relative;
            &::after {
              background: url("@/assets/img/contacts/form-art.svg") no-repeat;
              content: "";
              background-position: center;
              bottom: 60px;
              height: 700%;
              pointer-events: none;
              background-size: cover;
              position: absolute;
              left: -320%;
              width: 680%;
              z-index: -1;
              @media all and (min-width: 2000px) {
                bottom: 80px;
                left: -375%;
                width: 850%;
              }
            }
          }
        }
      }
    }
    .map {
      border: 5px solid $mainColor;
      border-radius: 10px;
      position: relative;
      top: -10vh;
      width: 35%;
      // transform: translateY(-10%);
      // &::before {
      //   background: blue;
      //   content: "";
      //   height: calc(100% + 10px);
      //   left: -5px;
      //   position: absolute;
      //   top: -5px;
      //   width: calc(100% + 10px);
      //   z-index: -1;
      // }
      &.mobile {
        display: none;
      }
      iframe {
        border-radius: 10px;
        height: 100%;
        width: 100%;
      }
      &:hover {
        .map-hover {
          opacity: 1;
        }
      }
      img {
        position: relative;
        @include tr();

        &.map-hover {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }
      }
    }
  }
}

@media all and (max-width: 1800px) {
  .content-wrapper {
    .content {
      .form-wrapper {
        max-width: 650px;
        padding-left: 10%;

        .links {
          transform: translateX(110%);
        }
      }
    }
  }
}

@media all and (max-width: 1365px) {
  .content-wrapper {
    .content {
      margin-top: 20px;
      .form-wrapper {
        align-items: center;
        gap: 20px;
        padding: 0;
        flex-direction: column-reverse;
        .links {
          flex-direction: column;
          gap: 10px;
          transform: initial;
        }
        .button {
          margin: 0;
        }
      }
      .map {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
          position: initial;
          width: calc(100% - 40px);
          height: 25vh;
        }
      }
    }
  }
}
</style>
