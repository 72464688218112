<template>
  <main
    id="homeView"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 400,
    }"
  >
    <section class="home">
      <video playsinline autoplay muted loop :class="{ visible }">
        <source src="@/assets/img/home/bg.mp4" type="video/mp4" />
      </video>

      <div class="home-content">
        <section>
          <div>
            <h1>{{ $t("home.t1") }}</h1>
            <h1>{{ $t("home.t2") }}</h1>
            <span>{{ $t("home.t3") }}</span>
          </div>
          <div>
            <div class="mobile-button-wrapper">
              <transition name="slide-top" appear mode="out-in">
                <div>
                  <Button
                    @click="scrollTo('contactsView')"
                    type="white"
                    :mainText="$t('buttons.header.main')"
                    :span="$t('buttons.header.span')"
                    icon="clock.svg"
                    :service="true"
                  />
                </div>
              </transition>
            </div>
            <img
              class="scroll-img"
              src="@/assets/img/scroll-light.svg"
              alt=""
            />
          </div>
        </section>
      </div>
    </section>
    <img class="over-home--svg" src="@/assets/img/home/video-art.svg" alt="" />
  </main>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "HomeView",
  components: {
    Button,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
    scroll(el) {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      window.scrollTo({
        top: el.getBoundingClientRect().top + scrollTop - 5,
        behavior: "smooth",
      });
    },
    scrollTo(id) {
      if(id && id === 'contactsView') localStorage.setItem('to-contacts', true)

      const target = document.getElementById(id);
      if (target) {
        this.scroll(target);
        setTimeout(() => {
          this.scroll(target);
        }, 1000);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";

main {
  height: 101vh;
  max-height: 101vh;
  min-height: 101vh;
  height: calc(var(--vh, 1vh) * 101);
  max-height: calc(var(--vh, 1vh) * 101);
  min-height: calc(var(--vh, 1vh) * 101);
  position: relative;
  overflow: hidden;
  width: 100%;

  .over-home--svg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    mix-blend-mode: exclusion;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 100vw;
  }

  .home {
    height: 100%;
    overflow: hidden;
    position: relative;

    video {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 103%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      opacity: 1;
      transform: translate(-50%, -50%) scale(2);
      z-index: -1;
      @include trLong();

      &.visible {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    .home-content {
      align-items: flex-end;
      background: rgb(165, 13, 6, 0.5);
      color: white;
      display: flex;
      height: 100%;
      justify-content: flex-start;

      &::before {
        background: transparent
          linear-gradient(
            90deg,
            #00143c 0%,
            #00143c66 25%,
            #00143c00 50%,
            #00143c66 75%,
            #00143c 100%
          );
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
      }

      section {
        display: flex;
        gap: 100px;
        flex-direction: column;
        margin: 0 0 6% 10%;
        max-width: 50%;
        position: relative;
        z-index: 2;

        h1 {
          letter-spacing: -1.25px;
          font: normal normal bold 80px/65px Akrobat;

          &:nth-of-type(2) {
            font-weight: 100;
            font: normal normal 200 90px/65px Akrobat;
          }
        }

        span {
          display: block;
          font: normal normal 300 30px/32px Akrobat;
          margin-top: 2rem;
          letter-spacing: -0.38px;
          max-width: 80%;
        }

        .mobile-button-wrapper {
          display: none;
        }

        img {
          width: 40px;
        }
      }
    }
  }
}

@media all and (max-width: $mobile) {
  main {
    .over-home--svg {
      display: none;
    }

    .home {
      .home-content {
        align-items: center;
        justify-content: center;
        text-align: center;

        section {
          align-items: center;
          justify-content: space-between;
          height: 100%;
          margin: 0;
          max-width: 100%;
          padding: calc(60px + 2rem) 1rem 2rem;
          position: relative;
          z-index: 2;

          > div:first-child {
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            margin-top: 10vh;
          }

          h1 {
            font-size: 50px;
            line-height: 50px;

            &:nth-of-type(2) {
              font: normal normal 200 48px/38px Akrobat;
            }
          }

          span {
            margin-top: 2rem;
            font-size: 20px;
            line-height: 20px;
            max-width: 100%;
          }

          .mobile-button-wrapper {
            display: block;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
</style>
