<template>
  <SubTemplate
    :name="$t(`certificates.title`)"
    :desc="$t(`certificates.title-2`)"
    cover="projects/project-1/2.jpg"
  >
    <section class="sub-cards sub-cards--max-width">
      <a
        class="card"
        v-for="cert in 10"
        :key="$t(`certificates.cert-${cert}.name`)"
        :href="$t(`certificates.cert-${cert}.link`)"
        target="_blank"
      >
        <div class="row">
          <img src="../../certificates/pdf.svg" alt="" />
          <p>{{ $t(`certificates.cert-${cert}.name`) }}</p>
        </div>
      </a>
    </section>
  </SubTemplate>
</template>

<script>
import SubTemplate from "@/components/SubTemplate";
export default {
  components: { SubTemplate },
  name: "TheCertificates",
};
</script>

<style scoped lang="scss">
@import "@/scss/base.scss";

.template-sub {
  .sub-cards {
    &:first-of-type {
      margin-bottom: 50px;
    }

    .card {
      &:hover {
        .row {
          p {
            text-decoration: underline;
          }
        }
      }
      .row {
        display: flex;
        gap: 10px;
      }
    }
  }
}
</style>