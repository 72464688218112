<template>
  <header>
    <!-- TODO: make dark transition better -->
    <div class="logo-wrapper" @click="scrollTo('homeView')">
      <transition name="slide-top" appear mode="out-in">
        <img v-if="dark" src="@/assets/img/logo.svg" alt="" />
        <div v-else>
          <img src="@/assets/img/logo-light.svg" alt="" />
        </div>
      </transition>
    </div>

    <section class="menu-toggle-and-button">
      <transition name="slide-top" appear mode="out-in">
        <Button
          v-if="dark"
          @click="scrollTo('contactsView')"
          :mainText="$t('buttons.header.main')"
          :span="$t('buttons.header.span')"
          icon="clock.svg"
          :service="true"
        />
        <div v-else>
          <Button
            @click="scrollTo('contactsView')"
            type="white"
            :mainText="$t('buttons.header.main')"
            :span="$t('buttons.header.span')"
            icon="clock.svg"
            :service="true"
          />
        </div>
      </transition>
      <div class="menu-toggle" @click="isMenuOpen = true">
        <transition name="slide-top" appear mode="out-in">
          <img v-if="dark" src="@/assets/img/menu-l.svg" alt="" />
          <div v-else>
            <img src="@/assets/img/menu.svg" alt="" />
          </div>
        </transition>
      </div>
    </section>

    <transition name="modal" appear>
      <MobileMenu v-if="isMenuOpen" @close="closeMobileMenu" />
    </transition>
  </header>
</template>

<script>
import MobileMenu from "@/components/MobileMenu";
import Button from "@/components/Button";
export default {
  name: "TheHeader",
  components: { MobileMenu, Button },
  data() {
    return {
      dark: false,
      isMobile: false,
      isMenuOpen: false,
      y: 0,
    };
  },
  methods: {
    closeMobileMenu(id) {
      if (id) this.scrollTo(id);
      this.isMenuOpen = false;
    },
    scroll(el) {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      window.scrollTo({
        top:
          el.getBoundingClientRect().top + scrollTop - (this.isMobile ? 75 : 5),
        behavior: "smooth",
      });
    },
    scrollTo(id) {
      const target = document.getElementById(id);
      if (target) {
        this.scroll(target);
        setTimeout(() => {
          this.scroll(target);
        }, 1000);
      }
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    changeLanguage(lng) {
      this.$i18n.locale = lng;
      localStorage.setItem("locale", this.$i18n.locale);
    },
    handleScroll() {
      this.y = window.scrollY;

      let dark = document.getElementById("aboutView");
      let darkOffsetTop = dark ? dark.offsetTop - 50 : 0;

      if (this.y > darkOffsetTop || this.isMobile) {
        this.dark = true;
      } else {
        this.dark = false;
      }
    },
    handleResize() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
        this.dark = true;
      } else {
        this.handleScroll();
        this.isMobile = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleScroll();
    this.handleResize();

    let sp = localStorage.getItem("sp");

    // scrolls to previous scroll position when going back
    this.$router.onReady(() => {
      setTimeout(() => {
        if (sp) {
          window.scrollTo({ top: sp, behavior: "smooth" });
          localStorage.removeItem("sp");
        }
      }, 1000);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/base.scss";

.slide-home-enter-active,
.slide-home-leave-active {
  @include transition($transition);
}

header {
  align-items: center;
  color: white;
  display: flex;
  justify-content: space-between;
  position: fixed;
  right: 0;
  top: 0;
  padding: 50px 20px;
  width: 100%;
  height: 60px;
  z-index: 99;
  @include tr();

  &.dark {
    color: #041f5e;
  }

  .logo-wrapper {
    img {
      cursor: pointer;
      height: 60px;
    }
  }

  .menu-toggle-and-button {
    align-items: center;
    display: flex;
    gap: 40px;
  }

  .menu-toggle {
    cursor: pointer;
    @include tr();

    &:hover {
      transform: rotate(-90deg);
    }
  }
}

@media all and (max-width: $mobile) {
  header {
    background: rgb(255, 255, 255, 0.9);
    padding: 35px 20px;

    .logo-wrapper {
      img {
        height: 40px;
      }
    }
    .button {
      display: none;
    }
  }
}
</style>
