<template>
  <button
    class="button"
    :class="type ? `button--${type}` : null"
    @click="$emit('click')"
  >
    <div>
      <h1>{{ mainText }}</h1>
      <p>{{ span }}</p>
    </div>

    <img v-if="icon" :src="`${require(`@/assets/img/${icon}`)}`" alt="" />
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        id="arrow-right"
        d="M24.134,32.634a1.25,1.25,0,0,0,0-1.768L18.518,25.25H32.75a1.25,1.25,0,0,0,0-2.5H18.518l5.616-5.616a1.25,1.25,0,0,0-1.768-1.768l-7.75,7.75a1.25,1.25,0,0,0,0,1.768l7.75,7.75A1.25,1.25,0,0,0,24.134,32.634ZM24,44A20,20,0,1,0,4,24,20,20,0,0,0,24,44ZM41.5,24A17.5,17.5,0,1,1,24,6.5,17.5,17.5,0,0,1,41.5,24Z"
        transform="translate(44 44) rotate(180)"
        fill="#fff"
      />
    </svg>

    <p v-if="service" class="hover-text">
      МИВ Инженеринг е оторизиран сервизен партньор и дистрибутор на всички
      предлагани системи
    </p>
    <!-- <slot></slot> -->
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: ["type", "mainText", "span", "icon", "service"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";

.button {
  align-items: center;
  background: url("../assets/img/button-bg.svg") no-repeat;
  background-position: center;
  background-size: cover;
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  display: flex;
  font-size: $px12;
  height: 40px;
  justify-content: space-evenly;
  outline: none;
  position: relative;
  // overflow: hidden;
  width: 300px;
  max-width: 300px;
  @include noselect();
  @include tr();

  &.button--white {
    background: url("../assets/img/button-bg-w.svg") no-repeat;
    background-position: center;
    background-size: contain;
    color: $mainColor;

    .hover-text {
      color: white;
    }

    img {
      filter: initial;
    }

    svg {
      path {
        fill: $mainColor;
      }
    }
  }

  .hover-text {
    position: absolute;
    color: $mainColor;
    content: "";
    top: calc(100% + 15px);
    left: -50px;
    width: 310px;
    pointer-events: none;
    opacity: 0;
    z-index: 10;
    @include tr();
  }

  img {
    filter: brightness(0) invert(1);
  }

  img,
  svg {
    width: 25px;
    @include tr();
  }

  div {
    text-align: left;

    h1 {
      font-size: 15px;
      @include tr();
    }

    p {
      opacity: 0.8;
      @include tr();
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      // width: 320px;

      .hover-text {
        opacity: 0.9;
        left: 0;
      }

      img,
      svg,
      h1 {
        transform: translateX(-10px);
      }

      p {
        transform: translateX(15px);
      }
    }
  }
}

@media all and (max-width: $mobile) {
  .button {
    margin-bottom: 5rem;
    .hover-text { 
      opacity: 0.9;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
