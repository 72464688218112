<template>
  <main class="cards__wrapper" :class="{ 'cards__wrapper--sub': inSubPage }">
    <div
      class="cards"
      @mousedown="!inSubPage ? mouseDownHandler : null"
      @mouseup="!inSubPage ? mouseUpHandler : null"
      @mouseleave="!inSubPage ? mouseUpHandler : null"
    >
      <div
        class="cards__card"
        v-for="card of cards"
        :key="`card-${card}`"
        @click="$emit('selectCard', card)"
      >
        <section
          class="cards__info"
          :style="{
            background: `url(${require(`@/assets/img/${folder}/${card}.jpg`)})`,
          }"
        >
          <template v-if="folder === 'news'">
            <p>{{ $t(`news.news-${card}.name`) }}</p>
            <span>{{ $t(`news.news-${card}.desc`) }}</span>
          </template>
          <template v-else>
            <p>{{ $t(`services.card-${card}.name`) }}</p>
            <span>{{ $t(`services.card-${card}.desc`) }}</span>
          </template>
        </section>
        <p class="learn-more">{{ $t('find-out-more') }}</p>
      </div>
    </div>

    <!-- <div class="arrows">
      <img @click="scrollTo()" src="@/assets/img/arrow-left.svg" alt="" />
      <img @click="scrollTo(true)" src="@/assets/img/arrow-right.svg" alt="" />
    </div> -->
  </main>
</template>

<script>
export default {
  name: "TheCards",
  props: ["cards", "folder", "inSubPage"],
  data() {
    return {
      pos: {},
      isServicesModalOpen: false,
      ele: "",
    };
  },
  methods: {
    scrollTo(right) {
      let scrollAmount =
        document.getElementsByClassName("cards__card")[0].offsetWidth;
      if (right) this.ele.scrollLeft += scrollAmount + 20;
      else this.ele.scrollLeft -= scrollAmount + 20;
    },
    mouseDownHandler(e) {
      this.ele.style.cursor = "grabbing";
      this.ele.style.userSelect = "none";

      this.pos = {
        // The current scroll
        left: this.ele.scrollLeft,
        // Get the current mouse position
        x: e.clientX,
      };

      window.addEventListener("mousemove", this.mouseMoveHandler);
      window.removeEventListener("mouseup", this.mouseUpHandler);
    },
    mouseUpHandler() {
      window.removeEventListener("mousemove", this.mouseMoveHandler);
      window.removeEventListener("mouseup", this.mouseUpHandler);
      const ele = document.getElementsByClassName("cards")[0];

      ele.style.cursor = "grab";
      ele.style.removeProperty("user-select");
    },
    mouseMoveHandler(e) {
      const ele = document.getElementsByClassName("cards")[0];

      const dx = e.clientX - this.pos.x;
      ele.scrollLeft = this.pos.left - dx;
    },
  },
  mounted() {
    this.ele = document.getElementsByClassName("cards")[0];
  },
  destroyed() {
    window.removeEventListener("mousemove", this.mouseMoveHandler);
    window.removeEventListener("mouseup", this.mouseUpHandler);
  },
};
</script>

