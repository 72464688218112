<template>
  <main
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 400,
    }"
    class="template-main"
  >
    <transition name="slide-home" appear mode="out-in">
      <div class="static" :class="{ visible }">
        <h1>{{ h1 }}</h1>
        <p>{{ p }}</p>
        <span v-html="span" ref="descriptionSpan"></span>
      </div>
    </transition>

    <main
      class="main-content"
      :style="{ 'padding-top': `${paddingTop + 20}px` }"
    >
      <div class="bg" :class="{ visible }">
        <!-- :style="{
          backgroundImage: bg ? `url(${require(`@/${bg}`)})` : '',
        }" -->
        <img v-if="bg" :src="require(`@/${bg}`)" alt="" />
      </div>

      <transition name="slide-home" appear mode="out-in">
        <slot />
      </transition>
      <video
        v-if="videoBackgroud"
        playsinline
        autoplay
        muted
        loop
        :class="{ visible }"
      >
        <source src="@/assets/img/green/green-video.mp4" type="video/mp4" />
      </video>
    </main>
  </main>
</template>

<script>
export default {
  name: "TemplateComponent",
  props: ["bg", "h1", "p", "span", "videoBackgroud"],
  data() {
    return {
      visible: false,
      paddingTop: 0,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
      if(v) this.$emit('visible', v)
    },
    setTopPadding() {
      this.paddingTop = this.$refs.descriptionSpan.clientHeight;
    },
  },
  mounted() {
    this.setTopPadding();
    window.addEventListener("resize", this.setTopPadding);
  },
  destroyed() {
    window.removeEventListener("resize", this.setTopPadding);
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/base.scss";

.template-main {
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  width: 100%;
  z-index: 1;
  @include trLong();

  &.template-main--projects {
    .main-content {
      max-height: 750px;
      overflow: visible;
    }
  }

  &:last-child {
    padding-bottom: 3rem;
  }

  .static {
    display: flex;
    flex-direction: column;
    margin: 2% 0 0 10%;
    position: relative;
    z-index: 2;
    @include trLong();
    @include transform(translateX(-100%));
    opacity: 0;

    h1 {
      color: $secondColor;
      font: normal normal 900 9vh/9vh Akrobat;
      letter-spacing: -0.76px;
    }

    p {
      color: $mainColor;
      font: normal normal 100 6vh/6vh Akrobat;
      letter-spacing: -0.69px;
      min-height: 6vh;
    }

    span {
      color: #ffffff;
      display: block;
      font: normal normal 100 2.5vh/2.5vh Akrobat;
      letter-spacing: -0.33px;
      margin-top: 1rem;
      max-width: 60%;
      opacity: 0.9;
      position: absolute;
      left: 0;
      top: 100%;
    }

    &.visible {
      @include transform(translateX(0));
      opacity: 1;
    }
  }

  .main-content {
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    @include trLong();

    .bg {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      clip-path: inset(0);
      height: 100%;
      left: 0;
      position: absolute;
      pointer-events: none;
      top: 0;
      transition: none;
      width: 100%;
      z-index: -3;
      img {
        background-attachment: fixed;
        height: 250%;
        position: fixed;
        left: -75%;
        top: -75%;
        object-fit: cover;
        width: 250%;
        z-index: -3;
        @include trLong();
      }
      &.visible {
        img {
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
        }
      }
    }

    &::before {
      background-color: rgba($color: $mainColor, $alpha: 0.8);
      background-size: cover;
      background-position: center;
      content: "";
      left: 0;
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      z-index: -2;
    }

    &::after {
      background: url("../assets/img/main-bg.svg") no-repeat;
      background-size: cover;
      background-position: 0 0;
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
  }

  video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 103%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) scale(2);
    z-index: -3;
    @include trLong();

    &.visible {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &.template-main--green {
    .main-content {
      &::before {
        background-color: rgba($color: #145700, $alpha: 0.8);
      }
    }

    .static {
      h1,
      p {
        color: #145700;
      }
    }
  }
}

@media all and (max-width: $mobile) {
  .template-main {
    background-size: cover !important;
    height: initial;
    min-height: 700px;
    max-height: initial;
    @include tr();

    &.visible {
      background-size: cover !important;
    }

    .static {
      margin: 0 20px;

      h1 {
        font-size: 36px;
        line-height: 36px;
      }

      p {
        font-size: 30px;
        line-height: 30px;
      }

      span {
        font-size: 18px;
        line-height: 18px;
        max-width: initial;
      }
    }

    .main-content {
      .bg {
        clip-path: polygon(85% 0, 100% 5%, 100% 100%, 15% 100%, 0 95%, 0% 0%);
      }
      &::before {
        clip-path: polygon(85% 0, 100% 5%, 100% 100%, 15% 100%, 0 95%, 0% 0%);
      }
      &::after {
        background-image: initial;
      }
    }
  }
}
</style>
