<template>
  <main>
    <transition name="appear" appear>
      <section class="mobile-menu">
        <section class="logo-and-images">
          <img class="logo" src="@/assets/img/logo-light.svg" alt="" />

          <section class="images-wrapper">
            <div class="images" :class="{ 'images--en': $i18n.locale !== 'bg' }">
              <img @click="close(r)" v-for="(r, i) of routes" :key="`${r}-${i}`"
                :class="{ 'hidden-image': $i18n.locale !== 'bg' && r === 'newsView' }" :src="`${require(`@/assets/img/menu/${$i18n.locale}${i + 1
                    }.png`)}`" />
            </div>
          </section>
        </section>

        <section class="lng-and-close">
          <div class="languages">
            <p :class="{ active: $i18n.locale === 'bg' }" @click="changeLanguage('bg')">
              БЪЛГАРСКИ
            </p>
            <p :class="{ active: $i18n.locale === 'en' }" @click="changeLanguage('en')">
              ENGLISH
            </p>
          </div>

          <img @click="close" class="close" src="@/assets/img/close-menu.svg" alt="close img" />
        </section>
      </section>
    </transition>
  </main>
</template>

<script>
export default {
  name: "MobileMenu",
  data() {
    return {
      routes: [
        "aboutView",
        "projectsView",
        "servicesView",
        "newsView",
        // "serviceAndMaintenanceView",
        "achievementsView",
        "contactsView",
        "careersView",
        "greenView",
      ],
    };
  },
  methods: {
    close(id) {
      this.$emit("close", id);
    },
    changeLanguage(lng) {
      this.$i18n.locale = lng;
      localStorage.setItem("locale", this.$i18n.locale);
    },
  },
  mounted() {
    document.body.classList.add("noscroll");
  },
  destroyed() {
    document.body.classList.remove("noscroll");
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";

main {
  align-items: center;
  background: url("@/assets/img/menu-bg.jpg") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  @include trLong();

  .mobile-menu {
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 20px 0;
    width: 100%;

    .logo-and-images {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      gap: 5rem;
      justify-content: center;

      .logo {
        max-width: 300px;
        width: 80%;
      }

      .images-wrapper {
        max-width: 1000px;
        max-height: 450px;
        margin-right: -30px;
        flex-grow: 2;
        overflow: auto;

        .images {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          width: 100%;

          &.images--en {
            img {
              &:nth-child(6) {
                margin-left: 0;
              }
            }
          }

          img {
            cursor: pointer;
            width: calc(25% - 5px);
            height: fit-content;
            margin: 0;
            @include tr();

            &.hidden-image {
              opacity: 0;
              width: 10px;
              pointer-events: none;
            }

            &:nth-child(even) {
              margin-left: -20px;
            }

            &:nth-child(2n) {
              margin-right: -20px;
            }

            &:hover {
              @include transform(scale(0.98));
            }
          }
        }
      }
    }

    .lng-and-close {
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 1rem;
      right: 20px;
      top: 30px;
      position: absolute;

      .languages {
        display: flex;
        gap: 10px;

        p {
          cursor: pointer;
          font: normal normal bold 25px/57px Akrobat;
          letter-spacing: -0.31px;
          @include tr();

          &:not(.active) {
            @include hover();
          }

          &.active {
            color: $mainColor;
            text-decoration: underline;
          }
        }
      }

      .close {
        cursor: pointer;
        width: 35px;
      }
    }
  }
}

@media all and (max-width: $mobile) {
  main {
    .mobile-menu {
      flex-direction: column;
      position: relative;

      .logo-and-images {
        overflow: auto;

        .logo {
          width: 60%;
        }

        .images-wrapper {
          padding: 0 30px 0 20px;
          max-height: initial;
          max-width: 500px;

          .images {
            img {
              width: calc(50% - 5px);
            }
          }
        }
      }

      .lng-and-close {
        position: initial;
        width: 100%;

        .close {
          position: fixed;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}
</style>
