<template>
  <SubTemplate
    :name="$t(`history.title`)"
    :desc="$t(`history.title-2`)"
    cover="projects/project-1/1.jpg"
  >
    <p>{{ $t("history.subtitle.title") }}</p>
    <span>{{ $t("history.subtitle.s") }}</span>
  </SubTemplate>
</template>

<script>
import SubTemplate from "@/components/SubTemplate";
export default {
  components: { SubTemplate },
  name: "TheHistory",
};
</script>
