<template>
  <main>
    <img src="@/assets/img/home/loading_animated.svg" alt="" />
  </main>
</template>

<script>
export default {
  name: "TheLoading",
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/scss/base.scss";
main {
  align-items: center;
  background: white;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}
</style>
