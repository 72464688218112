<template>
  <main
    class="main-bg"
    :style="{
      backgroundImage: `url(${require(`@/assets/img/projects/project-1/${current}.jpg`)})`,
      backgroundSize: 'cover',
    }"
  >
    <header class="sub-page-header">
      <img @click="$router.go(-1)" src="@/assets/img/arrow-left.svg" alt="" />
      <router-link to="/" class="logo">
        <img src="@/assets/img/logo-light.svg" alt="" />
      </router-link>
    </header>
    <section class="content">
      <main class="mid-section">
        <section>
          <span>ПРОЕКТ С <strong>УНИКАЛНО</strong> ИЗПЪЛНЕНИЕ В БЪЛГАРИЯ</span>
          <h1>Garitage Park</h1>
        </section>

        <section>
          <!-- <img src="@/assets/img/arrow-left.svg" alt="" />
          <img src="@/assets/img/arrow-right.svg" alt="" /> -->
        </section>
      </main>

      <main class="bottom-info">
        <div class="arrows-and-logo">
          <section class="arrows">
            <!-- <img src="@/assets/img/arrow-left.svg" alt="" />
            <img src="@/assets/img/arrow-right.svg" alt="" /> -->
          </section>

          <img
            class="logo"
            src="@/assets/img/projects/project-1/client_logo.png"
            alt=""
          />
        </div>

        <div class="info">
          <span>Участие на МИВ Инженеринг със следните дейности</span>

          <span>✓ Компютърна (IT) система (LAN)</span>
          <span>✓ Телефония</span>
          <span>✓ Напълно изградена СКС</span>
          <span>✓ Видео наблюдение (CCTV) окабеляване</span>
          <span>✓ Пълна проектна документация и изпълнение </span>
          <span>✓ Изпълнение на всички свързани тестове и услуги </span>

          <span>✓ Система за контрол на достъпа </span>
          <span>✓ Вложени повече от 621'000 м. кабели до Февруари 2021</span>

          <Button
            class="button--big"
            mainText="Направете запитване"
            span="за подобен проект"
          />
        </div>
      </main>
    </section>
  </main>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "CityProjects",
  components: {
    Button,
  },
  data() {
    return {
      current: 1,
      projects: [
        { name: "Garitage Park", folder: "project-1", color: "#5F9460" },
      ],
    };
  },
  methods: {
    nextSlide() {
      this.current++;
      if (this.current > 5) this.current = 1;
      this.resetPlay();
    },
    resetPlay() {
      clearInterval(this.timer);
      this.play();
    },
    play() {
      let app = this;
      this.timer = setInterval(() => {
        app.nextSlide();
      }, 6500);
    },
  },
  mounted() {
    this.play();
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";
.main-bg {
  height: 100vh;
  position: relative;
  overflow: hidden;
  transition: background-image 500ms ease-out;

  .content {
    background: rgb(0, 20, 60, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .mid-section {
      align-items: flex-end;
      display: flex;
      justify-content: center;
      flex-basis: 50%;
      gap: 30%;
      @include tr();

      span {
        font-weight: 100;
        font-size: $px14;
        letter-spacing: 4px;
        strong {
          background: white;
          color: $secondColor;
          padding: 0 5px;
        }
      }
      h1 {
        font-size: 54px;
      }
    }

    .bottom-info {
      align-self: center;
      align-items: flex-end;
      display: flex;
      flex-basis: 50%;

      .arrows-and-logo {
        align-items: flex-end;
        display: flex;
        flex-direction: column;

        .arrows {
          display: flex;
          gap: 10px;
        }
        .logo {
          background: #5f9460;
          padding: 5px 20px;
          object-fit: scale-down;
          height: 100px;
        }
      }

      .info {
        background: #00143c;
        display: flex;
        flex-direction: column;
        padding: 15px 30px;
        height: 100px;
        width: 32vw;
        @include trLong();
        &:hover {
          height: 85%;
        }
        span {
          margin-bottom: 0.5rem;
          &:first-child {
            font-weight: bold;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>