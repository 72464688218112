<template>
  <main>
    <MainTemplate class="template-main--green" id="greenView" :h1="this.$t('green.h1')"
      :p="this.$t('green.p')" :videoBackgroud="true" :span="this.$t('green.span')">
      <img  :src="`${require(`@/assets/img/green/green-${$i18n.locale}.svg`)}`" alt="green text img" />
    </MainTemplate>
  </main>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
export default {
  name: "GreenView",
  components: { MainTemplate },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";

img {
  max-width: 1000px;
  object-fit: scale-down;
  margin: 0 auto;
  width: 100%;
}
</style>