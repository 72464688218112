<template>
  <div
    class="input-wrapper"
    :class="{
      'input-wrapper--invalid': invalid,
      'input-wrapper--disabled': disabled,
      'input-wrapper--dropdown': list,
    }"
  >
    <img
      v-if="list"
      class="icons"
      :class="{ open: open }"
      :src="`${require(`@/assets/img//contacts/dd.svg`)}`"
      alt="icon"
      @click="open = !open"
    />

    <input
      :value="value"
      :id="id || ''"
      :inputmode="inputmode ? 'numeric' : false"
      :type="type || 'text'"
      :placeholder="placeholder"
      :maxlength="maxlength ? maxlength : 100"
      :readonly="readonly ? readonly : null"
      @keypress.enter="$emit('save')"
      @input="$emit('input', $event.target.value)"
      @click="open = !open"
      @blur="open ? (open = false) : null"
    />
    <div
      v-if="list"
      class="input-wrapper__dropdown"
      :class="{ 'input-wrapper__dropdown--open': open }"
    >
      <p
        :class="{
          special: specialFirst && !i,
          selected: value ? l === value : false,
        }"
        v-for="(l, i) in list"
        :key="`dd-l-${i}`"
        @mousedown="$emit('input', l), $emit('item-selected'), (open = false)"
      >
        {{ prop ? l[prop] : l }}
      </p>
      <p v-if="!list.length" class="empty">няма резултати</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputComponent",
  props: [
    "value",
    "type",
    "placeholder",
    "list",
    "icon",
    "disabled",
    "readonly",
    "maxlength",
    "invalid",
    "id",
    "inputmode",
    "prop",
    "specialFirst",
  ],
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.input-wrapper {
  position: relative;
  width: 100%;
  @include tr();
  &.input-wrapper--dropdown {
    input {
      cursor: pointer;
      padding-right: 35px;
    }
  }
  &.input-wrapper--invalid {
    input {
      border-bottom: 1px solid $mainColor;
    }
  }
  &.input-wrapper--disabled {
    input,
    .icons {
      opacity: 0.5;
      @include tr();
    }
  }
  .icons {
    cursor: pointer;
    right: 10px;
    position: absolute;
    top: 30%;
    @include tr();
    &.open {
      transform: rotate(180deg);
    }
  }

  input {
    background: transparent;
    border: 0;
    border-bottom: 3px solid white;
    color: white;
    font-weight: 700;
    font-size: $px14;
    height: 35px;
    outline: none;
    padding: 5px 10px;
    width: 100%;
    @include tr();
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }
  }

  .input-wrapper__dropdown {
    background-color: #fff;
    box-shadow: 0px 0px 57px #0000005c;
    border-radius: 0px 0px 6px 6px;
    border: 1px solid #d3d3d3;
    left: 0;
    position: absolute;
    overflow: auto;
    height: 0;
    max-height: 200px;
    top: 100%;
    width: 100%;
    z-index: 10;
    transform-origin: top;
    @include tr();
    @include transform(scaleY(0));

    &.input-wrapper__dropdown--open {
      height: auto;
      @include transform(scaleY(1));
    }
    > p {
      font-weight: bold;
      font-size: $px12;
      padding: 0.6rem 0.8rem;
      @include tr();

      &.selected {
        &:not(.special) {
          background-color: #ffe8e8;
        }
      }

      &.special {
        padding-top: 14px;
        color: #fff;
        margin-bottom: 2px;
        position: relative;

        &::before {
          background: $mainColor;
          border-radius: 4px;
          content: "";
          width: calc(100% - 10px);
          height: calc(100% - 8px);
          left: 5px;
          position: absolute;
          top: 7px;
          z-index: -1;
          @include tr();
        }
      }

      &:not(.empty) {
        cursor: pointer;
        &:hover:not(.selected):not(.special) {
          background-color: #f2f2f2;
        }
        &.special:hover:not(.selected) {
          &::before {
            background: rgba($color: $mainColor, $alpha: 0.8);
          }
        }
      }
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px rgb(165, 13, 6, 0.5) inset;
  -webkit-box-shadow: 0 0 0 30px rgb(165, 13, 6, 0.5) inset;
  -webkit-text-fill-color: white;
  border-radius: 3px 3px 0 0;
}
</style>
