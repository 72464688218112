var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-wrapper",class:{
    'input-wrapper--invalid': _vm.invalid,
    'input-wrapper--disabled': _vm.disabled,
    'input-wrapper--dropdown': _vm.list,
  }},[(_vm.list)?_c('img',{staticClass:"icons",class:{ open: _vm.open },attrs:{"src":`${require(`@/assets/img//contacts/dd.svg`)}`,"alt":"icon"},on:{"click":function($event){_vm.open = !_vm.open}}}):_vm._e(),_c('input',{attrs:{"id":_vm.id || '',"inputmode":_vm.inputmode ? 'numeric' : false,"type":_vm.type || 'text',"placeholder":_vm.placeholder,"maxlength":_vm.maxlength ? _vm.maxlength : 100,"readonly":_vm.readonly ? _vm.readonly : null},domProps:{"value":_vm.value},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('save')},"input":function($event){return _vm.$emit('input', $event.target.value)},"click":function($event){_vm.open = !_vm.open},"blur":function($event){_vm.open ? (_vm.open = false) : null}}}),(_vm.list)?_c('div',{staticClass:"input-wrapper__dropdown",class:{ 'input-wrapper__dropdown--open': _vm.open }},[_vm._l((_vm.list),function(l,i){return _c('p',{key:`dd-l-${i}`,class:{
        special: _vm.specialFirst && !i,
        selected: _vm.value ? l === _vm.value : false,
      },on:{"mousedown":function($event){_vm.$emit('input', l), _vm.$emit('item-selected'), (_vm.open = false)}}},[_vm._v(" "+_vm._s(_vm.prop ? l[_vm.prop] : l)+" ")])}),(!_vm.list.length)?_c('p',{staticClass:"empty"},[_vm._v("няма резултати")]):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }