<template>
  <SubTemplate
    class="template-sub--blue"
    v-if="selectedService"
    :invalid="invalid"
    :name="$t(`services.service-${selectedService}.name`)"
    :desc="$t(`services.service-${selectedService}.desc`)"
    :descTwo="$t(`services.service-${selectedService}.descTwo`)"
    :cover="`services/${selectedService}.jpg`"
  >
    <template v-if="selectedService === '4'">
      <section class="sub-cards sub-cards--partners">
        <section class="card" v-for="(p, i) of partners" :key="p.name">
          <div class="card__img">
            <img
              :src="`${require(`../assets/img/partners/${i + 1}.svg`)}`"
              alt=""
            />
          </div>
          <p>
            {{ p.name }}
          </p>
        </section>
      </section>
    </template>

    <template v-else>
      <template
        v-for="(m, i) in $t(`services.service-${selectedService}.article`)"
      >
        <p v-if="m.title" v-html="m.title" :key="`title-article-${i}`"></p>
        <span v-if="m.txt" v-html="m.txt" :key="`txt-article-${i}`"></span>
      </template>
    </template>
  </SubTemplate>
</template>

<script>
import SubTemplate from "@/components/SubTemplate";
import partners from "@/data/partners";
export default {
  components: { SubTemplate },
  name: "TheSelectedNews",
  data() {
    return {
      selectedService: "",
      text: {},
      invalid: false,
      partners,
    };
  },
  mounted() {
    this.selectedService = this.$route.params.service;
    if (this.selectedService > 4) this.invalid = true;
  },
};
</script>

