<template>
  <main
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 500,
    }"
    class="member-main"
    :class="`member--${member}`"
  >
    <div
      class="bg"
      :class="{ visible }"
      :style="{
        backgroundImage: `url(${require(`@/assets/img/management/members/${member}.jpg`)})`,
      }"
    ></div>
    <main class="content-wrapper" @click="open = true">
      <transition name="slide-left" appear mode="out-in" v-if="visible">
        <div class="member-content" :class="{ open }">
          <section class="name">
            <h1>
              <strong>{{ this.$t(`management.${member}.first-name`) }}</strong>
            </h1>
            <h1>{{ this.$t(`management.${member}.last-name`) }}</h1>

            <img
              src="@/assets/img/up-m.svg"
              class="arrow"
              :class="{ reverse: open }"
              @click.stop="open = !open"
            />
          </section>

          <section class="study">
            <p>
              <strong>{{ this.$t(`management.${member}.study`) }}</strong>
            </p>

            <p>{{ this.$t(`management.${member}.uni`) }}</p>
          </section>

          <div class="info">
            <section>
              <img src="@/assets/img/arrow-right.svg" alt="" />
              <span>{{ $t(`management.${member}.desc`) }}</span>
            </section>
            <section>
              <img src="@/assets/img/arrow-right.svg" alt="" />
              <span>{{ $t(`management.${member}.desc-2`) }}</span>
            </section>
            <section>
              <img src="@/assets/img/arrow-right.svg" alt="" />
              <span>{{ $t(`management.${member}.desc-3`) }}</span>
            </section>
          </div>
        </div>
      </transition>
    </main>
  </main>
</template>

<script>
export default {
  name: "MemberComponent",
  props: ["member"],
  data() {
    return {
      visible: false,
      open: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/base.scss";
.member-main {
  height: 101vh;
  max-height: 101vh;
  min-height: 101vh;
  height: calc(var(--vh, 1vh) * 101);
  max-height: calc(var(--vh, 1vh) * 101);
  min-height: calc(var(--vh, 1vh) * 101);
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 1;
  .bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(2);
    width: 100%;
    @include trLong();
    &.visible {
      transform: scale(1);
    }
  }
  .content-wrapper {
    height: 100%;
    position: relative;
    z-index: 1;

    .arrow {
      display: none;
    }

    .member-content {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      position: relative;
      padding-left: 12%;
      width: 42%;
      @include trLong();

      &::before {
        content: "";
        background: rgb(142, 6, 0, 0.8);
        position: absolute;
        left: 0;
        top: 0;
        width: 68%;
        height: 100%;
        z-index: -1;
      }

      h1 {
        font-size: 9vh;
        font-weight: 100;
        letter-spacing: 1.5px;
        line-height: 9vh;
      }

      p {
        font-size: 36px;
        white-space: nowrap;

        &:last-of-type {
          font-weight: 100;
          opacity: 0.8;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 60%;
        transform: translateX(-10%);
        section {
          align-items: flex-start;
          display: flex;
          gap: 10px;
          margin: 5px 0;
          img {
            width: 30px;
          }
          span {
            :nth-child(1) {
              font-size: 24px;
              font-weight: 300;
            }
            font-size: 20px;
            letter-spacing: -0.3px;
            font-weight: 100;
          }
        }
      }
    }
  }
}
@media all and (max-width: $mobile) {
  .member-main {
    &.member--miroslav-petkov .bg {
      background-position: 65%;
    }
    &.member--ivan-metodiev .bg {
      background-position: 70%;
    }
    &.member--vladimir-nikolov .bg {
      background-position: 70%;
    }
    .content-wrapper {
      display: flex;
      align-items: flex-end;
      .arrow {
        display: block;
        position: absolute;
        right: 5px;
        padding: 10px;
        top: 25px;
        @include trLong();
        &.reverse {
          transform: rotate(-180deg);
        }
      }
      .member-content {
        background: rgb(142, 6, 0, 0.8);
        padding: 10px;
        width: 100%;
        justify-content: flex-start;
        height: calc(80px + 2rem);
        @include trLong();

        &.open {
          overflow: auto;
          height: 60vh;
        }

        &::before {
          display: none;
        }

        h1 {
          font-size: 50px;
          line-height: 40px;
          &:nth-child(2) {
            margin-bottom: 2rem;
          }
        }

        p {
          font-size: 28px;
          margin-bottom: 1rem;
          white-space: normal;
        }

        .info {
          transform: initial;
          width: 100%;

          section {
            img {
              margin-top: 4px;
              width: 15px;
            }
            span {
              line-height: 20px;
            }
          }
        }

        div {
          gap: 10px;
          width: 100%;
          transform: initial;
          section {
            align-items: flex-start;
            display: flex;
            gap: 10px;
            margin: 5px 0;
            img {
              width: 30px;
            }
            span {
              :nth-child(1) {
                font-size: 24px;
                font-weight: 300;
              }
              font-size: 20px;
              letter-spacing: -0.3px;
              font-weight: 100;
            }
          }
        }
      }
    }
  }
}
</style>
