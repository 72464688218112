<template>
  <main class="services-main">
    <MainTemplate
      id="servicesView"
      bg="assets/img/bgs/0.jpg"
      :h1="this.$t('services.h1')"
      :p="this.$t('services.p')"
      :span="this.$t('services.span')"
    >
      <main>
        <TheCards
          :cards="4"
          @selectCard="selectService($event)"
          :folder="'services'"
        />
      </main>
    </MainTemplate>
    <!-- <ServicesModal v-if="isServicesModalOpen" :card="isServicesModalOpen" @close="isServicesModalOpen = false" /> -->
  </main>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
// import ServicesModal from "@/components/ServicesModal";
import TheCards from "@/components/TheCards";
export default {
  name: "ServicesView",
  components: { MainTemplate, TheCards },
  data() {
    return {
      isServicesModalOpen: false,
    };
  },
  methods: {
    selectService(s) {
      this.$router.push(`services/${s}`);
    },
    openServicesModal(card, index) {
      card.index = index;
      this.currentCard = card;
      this.isServicesModalOpen = true;
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/base.scss";
.services-main {
  .cards__wrapper {
    .cards {
      justify-content: center;
    }
  }
}
@media all and (max-width: $mobile) {
  .services-main {
    .cards__wrapper {
      .cards {
        justify-content: flex-start;
        .cards__card:first-child {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
