<template>
  <div id="app">
    <transition name="slide-top" appear mode="out-in">
      <TheHeader v-if="loaded && $route.name === 'HomeView'" />
    </transition>
    <transition name="fade" appear>
      <Loading v-if="!loaded" />
      <!-- <UnderConstruction v-else /> -->
      <router-view v-else />
    </transition>
    <vue-snotify />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
// import UnderConstruction from "@/views/UnderConstruction";
import TheHeader from "@/components/TheHeader";
export default {
  components: {
    TheHeader,
    Loading,
    // UnderConstruction,
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0);

      // sets scroll position when leaving homepage
      if (from.name === "HomeView") {
        localStorage.setItem("sp", this.y);
      }
    },
  },
  data() {
    return {
      loaded: false,
      y: 0,
    };
  },
  methods: {
    handleResize() {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    handleScroll() {
      if (this.$route.name === "HomeView") {
        this.y = window.scrollY;
      }
    },
  },
  mounted() {
    this.$router.onReady(() => {
      setTimeout(() => {
        this.loaded = true;
      }, 2000);
    });

    // We listen to the resize and scroll event
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
};
</script>
<style lang="scss">
@import "~vue-snotify/styles/material";
@import "./scss/base.scss";
@import "./scss/snotify.scss";
@import "./scss/reset.scss";
@import "./scss/common.scss";
@import "./scss/cards.scss";

@font-face {
  font-family: "Akrobat";
  src: url("./assets/fonts/Akrobat-Regular.otf") format("opentype"),
    url("./assets/fonts/Akrobat-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Akrobat";
  src: url("./assets/fonts/Akrobat-Light.otf") format("opentype"),
    url("./assets/fonts/Akrobat-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Akrobat";
  src: url("./assets/fonts/Akrobat-Thin.otf") format("opentype"),
    url("./assets/fonts/Akrobat-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Akrobat";
  src: url("./assets/fonts/Akrobat-Bold.otf") format("opentype"),
    url("./assets/fonts/Akrobat-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Akrobat";
  src: url("./assets/fonts/Akrobat-SemiBold.otf") format("opentype"),
    url("./assets/fonts/Akrobat-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Akrobat";
  src: url("./assets/fonts/Akrobat-ExtraBold.otf") format("opentype"),
    url("./assets/fonts/Akrobat-ExtraBold.otf") format("opentype");
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: "Akrobat";
  src: url("./assets/fonts/Akrobat-Black.otf") format("opentype"),
    url("./assets/fonts/Akrobat-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Bold.ttf") format("truetype"),
    url("./assets/fonts/Oswald-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Regular.ttf") format("truetype"),
    url("./assets/fonts/Oswald-Regular.ttf") format("truetype");
    font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Medium.ttf") format("truetype"),
    url("./assets/fonts/Oswald-Medium.ttf") format("truetype");
    font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Oswald-SemiBold.ttf") format("truetype");
    font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Light.ttf") format("truetype"),
    url("./assets/fonts/Oswald-Light.ttf") format("truetype");
    font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-ExtraLight.ttf") format("truetype"),
    url("./assets/fonts/Oswald-ExtraLight.ttf") format("truetype");
    font-weight: 200;
  font-style: normal;
}

#app {
  font-family: Akrobat, Oswald, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
