<template>
  <main>
    <MainTemplate
      id="newsView"
      bg="assets/img/bgs/0.jpg"
      :h1="this.$t('news.h1')"
      :p="this.$t('news.p')"
      :span="this.$t('news.span')"
    >
      <main class="news-wrapper">
        <router-link to="/news">
          <Button type="white" :mainText="$t('buttons.news.main')" :span="$t('buttons.news.span')" />
        </router-link>
        <TheCards :cards="3" folder="news" @selectCard="openNews" />
      </main>
    </MainTemplate>
  </main>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
import TheCards from "@/components/TheCards";
import Button from "@/components/Button";
export default {
  name: "newsView",
  components: { MainTemplate, Button, TheCards },
  data() {
    return {};
  },
  methods: {
    openNews(n) {
      this.$router.push(`/news/${n}`);
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/base.scss";

.news-wrapper {
  align-items: center;
  display: flex;
  gap: 100px;
  justify-content: center;
  margin-right: -10%;
}

@media all and (max-width: $mobile) {
  .news-wrapper {
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-right: initial;
    gap: 20px;
    width: 100%;
    > a {
      align-self: center;
    }
    .button {
      margin: 0;
    }
    .cards__wrapper {
      .cards {
        justify-content: flex-start;
        max-width: 100vw;
        width: 100%;
        .cards__card:first-child {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
