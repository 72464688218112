<template>
  <SubTemplate cover="projects/project-1/3.jpg" class="template-sub--projects">
    <section
      class="project"
      v-for="p in 10"
      :key="`project-${p}`"
      @click="openProject(p)"
    >
      <aside>
        <section>
          <p>{{ $t(`projects.project-${p}.name`) }}</p>
          <span>{{ $t(`projects.project-${p}.desc`) }}</span>
        </section>
        <img :src="require(`@/assets/img/projects/projects/${p}.svg`)" alt="" />
      </aside>

      <div class="activities-wrapp">
        <span>{{ $t(`projects.project-${p}.info`) }}</span>
        <p>
          <u v-if="p === 10">{{ $t(`projects-done`) }}</u>
          <u v-else>{{ $t(`activities-done`) }}</u>
        </p>

        <section class="activities" :class="{ 'activities-extra': p === 10 }">
          <span v-for="a in $t(`projects.project-${p}.article`)" :key="`project-${p}-${a.txt}`">
            {{ a.txt }}
          </span>
        </section>
      </div>
    </section>
  </SubTemplate>
</template>

<script>
import SubTemplate from "@/components/SubTemplate";
export default {
  components: { SubTemplate },
  name: "ProjectsView",
  methods: {
    openProject(p) {
      this.$router.push(`/projects/${p}`);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";

.project {
  align-items: center;
  background: url("@/assets/img/projects/project-border.svg") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  gap: 50px;
  max-width: 1300px;
  height: 500px;
  width: 1260px;
  @include trLong();

  &:hover {
    background-size: 90% 90%;
    aside {
      section {
        transform: translate(8%, 8%);
      }
      img {
        transform: translateX(8%);
      }
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    height: 100%;

    section {
      padding: 80px 0 0 110px;
      flex-basis: 40%;
      @include trLong();
      p {
        font: normal normal 800 34px/24px Akrobat;
      }
      span {
        font: normal normal normal 22px/24px Akrobat;
      }
    }

    img {
      @include trLong();
    }
  }
  .activities-wrapp {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font: normal normal 600 20px/28px Akrobat;
    }
    span {
      font: normal normal 300 18px/25px Akrobat;
      opacity: 0.8;
    }

    .activities {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;

      &.activities-extra {
        max-height: 400px;

        span {
          width: 100%;
        }
      }
    }
  }
}
@media all and (max-width: $mobile) {
  .project {
    background-size: cover;
    height: fit-content;
    flex-direction: column;
    gap: 10px;
    margin: 50px 0;
    padding: 0 10px;
    width: 100%;

    &:first-of-type {
      margin-top: 0;
    }

    aside {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: initial;
      width: 100%;

      section {
        padding: initial;
        flex-basis: initial;

        p {
          font: normal normal 800 24px/24px Akrobat;
        }

        span {
          font: normal normal normal 18px/20px Akrobat;
        }
      }

      img {
        margin-top: -10px;
        width: 50%;
      }
    }

    .activities-wrapp {
      .activities {
        &.activities-extra {
          max-height: initial;
        }
      }
    }
  }
}
</style>