<template>
  <SubTemplate
    v-if="selected"
    :invalid="invalid"
    :name="$t(`projects.project-${selected}.name`)"
    :desc="$t(`projects.project-${selected}.desc`)"
    :image="`projects/projects/${selected}.svg`"
    :cover="`projects/projects/${selected}-cover.jpg`"
    class="template-sub--selected-project"
  >
    <template v-for="(m, i) in $t(`projects.project-${selected}.article`)">
      <p v-if="m.title" v-html="m.title" :key="`title-article-${i}`"></p>
      <span v-if="m.txt" v-html="m.txt" :key="`txt-article-${i}`"></span>
    </template>
  </SubTemplate>
</template>

<script>
import SubTemplate from "@/components/SubTemplate";
export default {
  components: { SubTemplate },
  name: "TheSelectedProject",
  data() {
    return {
      selected: "",
      invalid: false,
    };
  },
  mounted() {
    this.selected = this.$route.params.project;
    if (this.selected > 10) this.invalid = true;
  },
};
</script>