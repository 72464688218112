<template>
  <main
    class="template-sub"
    :style="{
      backgroundImage: cover && !invalid ? `url(${require(`@/assets/img/${cover}`)})` : '',
    }"
    :class="{ 'template-sub--invalid': invalid }"
  >
    <img
      v-if="!invalid"
      class="scroll-img"
      src="@/assets/img/scroll-light.svg"
      alt=""
    />
    <header>
      <img @click="$router.go(-1)" src="@/assets/img/arrow-left.svg" alt="" />
      <router-link to="/" class="logo">
        <img src="@/assets/img/logo-light.svg" alt="" />
      </router-link>
    </header>
    <section class="content">
      <main class="main-content" v-if="!invalid">
        <div class="title">
          <h1>{{ name }}</h1>
          <h2>{{ desc }}</h2>
          <h2 v-if="descTwo">{{ descTwo }}</h2>
        </div>

        <div
          class="img-wrapper"
          v-if="image && !invalid"
          :style="{
            background: `url(${require(`@/assets/img/${image}`)})`,
          }"
        ></div>
        <section class="slot-wrapper">
          <slot />
        </section>
      </main>
      <main v-else class="main-content">
        {{ $t("nothing-found") }}
      </main>
    </section>
  </main>
</template>

<script>
export default {
  name: "TemplateComponent",
  props: ["name", "desc", "descTwo", "image", "cover", "invalid"],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/base.scss";

.template-sub {
  min-height: 100vh;
  position: relative;
  background-size: cover !important;
  background-attachment: fixed !important;

  .scroll-img {
    position: fixed;
    left: 50%;
    top: 90vh;
    z-index: 5;
  }
  &.template-sub--management {
    height: 101vh;

    .scroll-img {
      display: none;
    }

    .content {
      height: 101vh;
      margin-left: -400px;

      .main-content {
        background: none;
        backdrop-filter: none;
        display: flex;
        justify-content: flex-end;
        height: 100vh;
        width: 100%;
      }
    }

    .mobile {
      display: none;
    }
  }
  &.template-sub--blue {
    .content {
      .main-content {
        background: rgb(0, 20, 60, 0.8) !important;
      }
    }
  }
  &.template-sub--invalid {
    .content {
      align-items: center;
      justify-content: center;

      .main-content {
        align-items: center;
        background: rgb(0, 20, 60, 0.2) !important;
        background: none;
        font-size: 30px;
        height: 250px;
        justify-content: center;
        min-height: 50%;
        padding: 5px;
        width: 500px;
      }
    }
  }
  &.template-sub--selected-project {
    .content {
      .main-content {
        .img-wrapper {
          background-size: contain !important;
          min-height: 200px;
        }
      }
    }
  }
  &.template-sub--projects {
    .content {
      .main-content {
        width: initial;
      }
    }
    .scroll-img {
      left: 25%;
    }
  }

  > header {
    display: flex;
    gap: 20px;
    padding: 20px 0 0 20px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;

    img {
      cursor: pointer;
      width: 45px;
    }

    .logo {
      img {
        width: 100px;
      }
    }
  }

  .content {
    background: rgb(0, 20, 60, 0.5);
    color: white;
    display: flex;
    min-height: 100vh;
    justify-content: flex-end;

    .main-content {
      background: rgb(142, 6, 0, 0.8);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      margin: 20px;
      padding: 2rem;
      width: 45%;

      .img-wrapper {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        border-radius: 23px;
        min-height: 400px;
        margin: 0 0 2.5rem -300px;
        width: 929px;
      }

      .title {
        margin-bottom: 3rem;

        h1 {
          font: normal normal 900 46px/62px Akrobat;
        }

        h2 {
          font: normal normal 100 22px/38px Akrobat;
          opacity: 0.8;

          &:first-of-type {
            margin-bottom: 0.5rem;
          }
        }
      }

      .slot-wrapper {
        overflow: hidden;
        font-size: 20px;

        > h1 {
          margin-top: 2rem;
        }

        > p {
          font-weight: 600;
          margin: 2rem 0 1.5rem 0;
          text-decoration: underline;
          &:first-of-type {
            margin: 0 0 1.5rem 0;
          }
        }

        > span {
          display: block;
          font-weight: 300;
          margin-bottom: 1rem;
          opacity: 0.8;
        }
        .nothing-found {
          display: flex;
          justify-content: center;
          opacity: 1;
        }

        .sub-cards {
          display: flex;
          flex-wrap: wrap;
          gap: 20px 3%;

          &.sub-cards--max-width {
            .card {
              flex-basis: calc(100% - 10px);
              width: calc(100% - 10px);
            }
          }

          &.sub-cards--partners {
            .card {
              align-items: center;
              flex-basis: calc(40% - 10px);
              text-align: center;
              width: calc(50% - 10px);

              .card__img {
                border-radius: 0;
                box-shadow: none;
                min-height: 160px;
                max-height: 160px;
              }
            }
          }

          .card {
            flex-basis: 30%;
            display: flex;
            flex-direction: column;
            width: 30%;
            @include tr();

            &:hover {
              .card__img {
                transform: scale(0.98);
              }
            }

            p {
              font-size: 20px;
            }

            span {
              font-size: 18px;
              font-weight: 100;
            }

            .card__img {
              align-items: center;
              box-shadow: 15px 10px 20px black;
              background-color: white;
              border-radius: 15px;
              display: flex;
              min-height: 200px;
              max-height: 200px;
              justify-content: center;
              margin-bottom: 15px;
              overflow: hidden;
              @include tr();

              img {
                object-fit: contain;
                @include tr();
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: $mobile) {
  .template-sub {
    .scroll-img {
      left: 2rem;
      top: calc(20vh - 25px);
      width: 25px;
    }
    .content {
      .main-content {
        padding: 15px;

        .img-wrapper {
          min-height: 200px;
          margin: 0 0 1rem 0;
          width: 100%;
        }

        .slot-wrapper {
          .sub-cards {
            justify-content: center;
            .card {
              flex-basis: calc(100% - 10px) !important;
              width: calc(100% - 10px) !important;
            }
          }
        }
      }
    }

    &:not(.template-sub--management) {
      .content {
        align-items: flex-end;
        .main-content {
          height: 75vh;
          margin: 0;
          min-height: 60vh;
          // padding-bottom: 0;
          width: 100%;
          overflow: auto;

          .title {
            margin-bottom: 1rem;

            h1 {
              font-size: 36px;
              line-height: 36px;
              margin-bottom: 5px;
            }

            h2 {
              line-height: 26px;
            }
          }

          .slot-wrapper {
            overflow: initial;
          }
        }
      }
    }
    &.template-sub--management {
      animation: 12s move infinite;

      @keyframes move {
        0% {
          background-position: 10%;
        }
        25% {
          background-position: 10%;
        }
        50% {
          background-position: 85%;
        }
        75% {
          background-position: 85%;
        }
        100% {
          background-position: 10%;
        }
      }
      .desktop {
        display: none;
      }
      .content {
        margin: 0;
        .main-content {
          padding: 0;
          margin: 0;
        }
      }
      .mobile {
        display: block;
        width: 100%;
      }
    }
    &.template-sub--projects {
      .main-content {
        padding: 0px;
      }
    }
  }
}
</style>
