<template>
  <main>
    <MainTemplate
    class=""
      id="serviceAndMaintenanceView"
      bg="assets/img/bgs/0.jpg"
      :h1="this.$t('serviceAndMaintenance.h1')"
      :p="this.$t('serviceAndMaintenance.p')"
      :span="this.$t('serviceAndMaintenance.span')"
    >

    </MainTemplate>
  </main>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
export default {
  name: "ServiceAndMaintenance",
  components: { MainTemplate },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";
</style>