<template>
  <main>
    <MainTemplate
      id="projectsView"
      bg="assets/img/bgs/1.jpg"
      :h1="this.$t('projects.h1')"
      :p="this.$t('projects.p')"
      :span="this.$t('projects.span')"
      class="template-main--projects"
    >
      <main class="projects-wrapper">
        <router-link to="/projects" class="projects-btn">
          <Button
            type="white"
            :mainText="$t('buttons.projects.main')"
            :span="$t('buttons.projects.span')"
          />
        </router-link>

        <div
          class="image-wrapper"
          :class="{ 'image-wrapper--bul-hovered': isBulHovered }"
        >
          <img
            class="image-bul"
            @mouseover="isBulHovered = true"
            @mouseleave="isBulHovered = false"
            src="@/assets/img/projects/map-bg.svg"
            alt=""
          />
          <img class="image-eu" src="@/assets/img/projects/map-eu.svg" alt="" />
        </div>
      </main>
    </MainTemplate>
  </main>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
import Button from "@/components/Button";
export default {
  name: "ProjectsView",
  components: { MainTemplate, Button },
  data() {
    return {
      isBulHovered: false,
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";
.projects-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .projects-btn {
    position: relative;
    z-index: 1;
  }

  .image-wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 70%;

    &.image-wrapper--bul-hovered {
      .image-eu {
        transform: scale(6) translate(-5.5%, -30%);
        opacity: 0.2;
      }

      .image-bul {
        height: 54.5%;
        top: 20%;
        left: 34%;
        opacity: 1;
        width: 55%;
      }
    }

    .image-eu {
      transform: translate(15%, -13%);
      width: 65vw;
      @include trLong();
    }

    .image-bul {
      height: 10%;
      position: absolute;
      top: 61.5%;
      left: 69.5%;
      opacity: 0;
      width: 9%;
      z-index: 5;
      @include trLong();
    }
  }
}
@media all and (max-width: $mobile) {
  .projects-wrapper {
    flex-direction: column-reverse;
    gap: 50px;

    .image-wrapper {
      margin-top: 28vw;
      width: 100%;

      &.image-wrapper--bul-hovered {
        .image-bul {
          left: 30.5%;
          width: 56.5%;
        }
      }

      .image-eu {
        transform: scale(1.5) translate(15%, -13%);
        width: 100%;
      }
      .image-bul {
        height: 13%;
        top: 68.5%;
        left: 75.7%;
        width: 14.5%;
      }
    }
  }
}
</style>