<template>
  <SubTemplate
    :name="$t(`mission.title`)"
    :desc="$t(`mission.title-2`)"
    cover="projects/project-1/1.jpg"
  >
    <template v-for="(m, i) in $t(`mission.article`)">
      <p v-if="m.title" v-html="m.title" :key="`title-article-${i}`"></p>
      <span v-if="m.txt" v-html="m.txt" :key="`txt-article-${i}`"></span>
    </template>

  </SubTemplate>
</template>

<script>
import SubTemplate from "@/components/SubTemplate";
export default {
  components: { SubTemplate },
  name: "ThePMission",
};
</script>
