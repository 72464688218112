<template>
  <SubTemplate :name="$t(`news.title`)" :desc="$t(`news.title-2`)">
    <TheCards
      :inSubPage="true"
      :cards="3"
      folder="news"
      @selectCard="openNews"
    />
  </SubTemplate>
</template>

<script>
import TheCards from "@/components/TheCards";
import SubTemplate from "@/components/SubTemplate";
export default {
  components: { SubTemplate, TheCards },
  name: "TheCertificates",
  methods: {
    openNews(n) {
      this.$router.push(`/news/${n}`);
    },
  },
  mounted(){
    if(this.$i18n.locale !== 'bg') {
      this.$snotify.error(this.$t('no-english-news'));
      this.$router.push(`/`);
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";

.template-sub {
  background: url("../assets/img/projects/project-1/1.jpg") no-repeat;
}
</style>