<template>
  <div class="links">
    <a href="mailto:office@mivengineering.com" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41.221"
        height="32.061"
        viewBox="0 0 41.221 32.061"
      >
        <path
          id="mail"
          d="M6,19.6,24.729,30.838a1.431,1.431,0,0,0,1.473,0l13.746-8.247a8.065,8.065,0,0,0,1.548.149,7.983,7.983,0,0,0,3.436-.772V36.194a4.866,4.866,0,0,1-4.866,4.866h-29.2A4.867,4.867,0,0,1,6,36.194ZM34.252,11.29a8.022,8.022,0,0,0,2.488,9.888L25.466,27.941,6.082,16.31a6.013,6.013,0,0,1,5.93-5.02ZM41.5,9a5.725,5.725,0,1,1-5.725,5.725A5.725,5.725,0,0,1,41.5,9Z"
          transform="translate(-6 -9)"
          fill="#f1e5e4"
        />
      </svg>

      <section>
        <p>{{ $t("contacts.mail.p") }}</p>
        <span>{{ $t("contacts.mail.span") }}</span>
      </section>
    </a>
    <a href="tel:+35924425254" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35.915"
        height="36.047"
        viewBox="0 0 35.915 36.047"
      >
        <path
          id="call"
          d="M5.443,3.025a9.237,9.237,0,0,1,6.715-.734,4.34,4.34,0,0,1,2.7,2.048l2.02,3.523a5.625,5.625,0,0,1-1.865,7.547l-1.777,1.128a1.6,1.6,0,0,0-.837,1.745,15.373,15.373,0,0,0,3.4,5.8,1.625,1.625,0,0,0,2.02.109l.983-.574a5.625,5.625,0,0,1,7.742,2.11l1.808,3.229a4.135,4.135,0,0,1,.27,3.456,8.79,8.79,0,0,1-4.42,4.987,6.5,6.5,0,0,1-6.491-.531A36.638,36.638,0,0,1,6.607,24.534C2.881,18.146,1.941,12.706,2.047,8.653A6.463,6.463,0,0,1,5.443,3.025ZM26.125,2.17a1.125,1.125,0,0,0-1.093,1.967l.864.48a19.126,19.126,0,0,1,9.743,14.816l.071.708a1.125,1.125,0,0,0,2.239-.224l-.071-.708A21.376,21.376,0,0,0,26.989,2.65ZM23.489,8.2a1.125,1.125,0,0,1,1.544-.386l.279.168a14.626,14.626,0,0,1,6.9,10.137l.1.6a1.125,1.125,0,1,1-2.219.37l-.1-.6a12.375,12.375,0,0,0-5.84-8.577l-.279-.168A1.125,1.125,0,0,1,23.489,8.2Z"
          transform="translate(-2.039 -1.978)"
          fill="#f1e5e4"
        />
      </svg>

      <section>
        <p>{{ $t("contacts.phone.p") }}</p>
        <span>{{ $t("contacts.phone.span") }}</span>
      </section>
    </a>
    <a href="https://goo.gl/maps/HukxCruV1WqYJbuN9" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32.82"
        height="38.58"
        viewBox="0 0 32.82 38.58"
      >
        <path
          id="location"
          d="M37.9,28.113a16.411,16.411,0,1,0-28.708.5l.068.116a16.456,16.456,0,0,0,1.6,2.254h0l.02.024.017.02h-.037L20.6,41.366a3.861,3.861,0,0,0,5.621,0l9.738-10.338h-.037l.016-.018h0q.105-.124.208-.251a16.447,16.447,0,0,0,1.477-2.142Q37.769,28.367,37.9,28.113ZM23.415,26.685a5.792,5.792,0,1,1,5.792-5.792A5.792,5.792,0,0,1,23.415,26.685Z"
          transform="translate(-7 -4)"
          fill="#f1e5e4"
        />
      </svg>

      <section>
        <p>{{ $t("contacts.address.p") }}</p>
        <span>{{ $t("contacts.address.span") }}</span>
      </section>
    </a>
  </div>
</template>

<script>
export default {
  name: "ContactLinks",
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.links {
  color: #f1e5e4;
  display: flex;
  gap: 50px;
  justify-content: flex-end;
  white-space: nowrap;

  a {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    @include tr();

    &::before {
      background: url("@/assets/img/button-bg-w.svg") no-repeat;
      background-position: center;
      background-size: 100%;
      content: "";
      position: absolute;
      right: -25px;
      top: 0;
      height: 100%;
      width: 0;
      z-index: -1;
      @include tr();
    }

    &:hover {
      color: $mainColor;
      svg {
        path {
          fill: #a50d06;
        }
      }
      &::before {
        width: 120%;
        @include transform(scaleY(1.3));
      }
    }

    svg {
      path {
        @include tr();
      }
    }

    section {
      display: flex;
      flex-direction: column;
      p {
        font-weight: bold;
        font: normal normal bold 16px/20px Oswald;
      }
      span {
        font: normal normal 300 14px/15px Oswald;
        opacity: 0.8;
      }
    }
  }
}
</style>
