<template>
  <main>
    <MainTemplate id="careersView" bg="assets/img/careers/karieri.jpg" :h1="this.$t('careers.h1')"
      :p="this.$t('careers.p')" :span="this.$t('careers.span')">
      <section>
        <Button @click="openFileUpload" type="white" :mainText="$t('buttons.careers.main')"
          :span="$t('buttons.careers.span')" />

        <p>{{ $t('snotify.warnings.file-type') }}</p>
        <input type="file" value="C:\fakepath" id="cvFileInput" accept=".doc,.docx, .pdf"
          @change="onFilesUpdate($event)" />
      </section>
    </MainTemplate>
  </main>
</template>

<script>
import axios from 'axios';
import Button from "@/components/Button";
import MainTemplate from "@/components/MainTemplate";
export default {
  name: "TheCareers",
  components: { MainTemplate, Button },
  data() {
    return {
      file: {}
    };
  },
  methods: {
    openFileUpload() {
      const input = document.getElementById('cvFileInput');
      // input was not reactiong to same file upload
      input.value = null
      input.click()
    },
    onFilesUpdate(e) {
      if (e.target.files.length) {
        let file = e.target.files[0]

        if (!file.name.match(/\.(pdf|PDF|doc|DOC|docx|DOCX)$/)) {
          return this.$snotify.warning(this.$t('snotify.warnings.file-type'));
        }

        if (file.size / 1000 / 1000 > 10) {
          return this.$snotify.warning(this.$t('snotify.warnings.file-size'));
        }


        this.sendMail(file)
      }
    },
    sendMail(f) {
      const fd = new FormData();

      fd.append('file', f);

      axios.post(`${process.env.VUE_APP_BASE_URI}/email/careerApply`, fd)
        .catch((err) => {
          console.log(err);
        }).then(() => this.$snotify.success(this.$t('snotify.done.career-aply')));
    }
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";

section {
  align-items: center;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;

  p {
    color: #ffffff;
    opacity: 0.7;
  }

  input {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }
}
</style>