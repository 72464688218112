<template>
  <main>
    <SubTemplate
      cover="management/management.jpg"
      class="template-sub--management"
    >
    <img class="desktop" :src="`${require(`@/assets/img/management/years+text+inside-${$i18n.locale}.svg`)}`" alt="years+text+inside" />
    <img class="mobile" :src="`${require(`@/assets/img/management/years+text+inside-m-${$i18n.locale}.svg`)}`" alt="years+text+inside-mobile" />

    </SubTemplate>
    <Member member="miroslav-petkov" />
    <Member member="ivan-metodiev" />
    <Member member="vladimir-nikolov" />
  </main>
</template>

<script>
import Member from "@/components/Member";
import SubTemplate from "@/components/SubTemplate";
export default {
  name: "TheManagement",
  components: {
    Member,
    SubTemplate,
  },
  data() {
    return {};
  },
};
</script>
