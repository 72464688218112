<template>
  <MainTemplate
    id="internationalView"
    bg="assets/img/bgs/3.jpg"
    :h1="this.$t('international.h1')"
    :p="this.$t('international.p')"
    :span="this.$t('international.span')"
  >
    <section>
      <img src="@/assets/img/international/eu.svg" alt="" />
    </section>
  </MainTemplate>
</template>

<script>
import MainTemplate from "@/components/MainTemplate";
export default {
  name: "InernationalView",
  components: { MainTemplate },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/base.scss";
section {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 70%;
  img {
    transform: translateY(-10%);
    width: 75%;
  }
}
</style>