<template>
  <main
    class="uc"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 400,
    }"
  >
    <section class="home">
      <video playsinline autoplay muted loop :class="{ visible }">
        <source src="@/assets/img/home/bg.mp4" type="video/mp4" />
      </video>

      <div class="home-content">
        <section class="uc-text">
          <img src="@/assets/img/logo-light.svg" alt="" />

          <section class="text">
            <div>
              <h1>{{ $t("home.t1") }}</h1>
              <h1>{{ $t("home.t2") }}</h1>
            </div>
            <div>
              <span>Новият ни сайт е Under Construction.</span>
              <span>Очаквайте го скоро.</span>
            </div>
          </section>
        </section>

        <footer>
          <span>През това време, може да се свържете с нас тук:.</span>
          <ContactLinks />
        </footer>
      </div>
    </section>

    <img class="over-home--svg" src="@/assets/img/home/video-art.svg" alt="" />
  </main>
</template>

<script>
import ContactLinks from "@/components/ContactLinks";
export default {
  name: "UnderConstruction",
  components: {
    ContactLinks,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style  lang="scss">
@import "@/scss/base.scss";
.uc {
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
  width: 100%;

  .over-home--svg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    mix-blend-mode: exclusion;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 100vw;
  }
  .home {
    height: 100%;
    overflow: hidden;
    position: relative;

    video {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 103%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      opacity: 1;
      transform: translate(-50%, -50%) scale(2);
      z-index: -1;
      @include trLong();
      &.visible {
        transform: translate(-50%, -50%) scale(1);
      }
    }
    .home-content {
      align-items: center;
      background: rgb(165, 13, 6, 0.5);
      color: white;
      display: flex;
      height: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      &::before {
        background: transparent
          linear-gradient(
            90deg,
            #00143c 0%,
            #00143c66 25%,
            #00143c00 50%,
            #00143c66 75%,
            #00143c 100%
          );
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
      }

      .uc-text,
      footer {
        position: relative;
        z-index: 2;
      }

      .uc-text {
        text-align: center;
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 100px;
        margin-top: -50px;

        .text {
          display: flex;
          flex-direction: column;
          gap: 45px;
          h1 {
            letter-spacing: -1.25px;
            font: normal normal bold 80px/65px Akrobat;
            &:nth-of-type(2) {
              font-weight: 100;
              font: normal normal 200 90px/65px Akrobat;
            }
          }
          span {
            display: block;
            font: normal normal 100 30px/32px Akrobat;
            letter-spacing: -0.38px;
            opacity: 0.7;
          }
        }
        img {
          max-width: 450px;
        }
      }
      footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;

        span {
          font: normal normal 100 30px/32px Akrobat;
          letter-spacing: -0.38px;
          opacity: 0.7;
        }
        .links {
          a {
            section {
              p {
                font: normal normal bold 20px/25px Oswald;
              }
              span {
                font: normal normal 300 18px/20px Oswald;
              }
            }
          }
        }
      }
    }
  }
}
@media all and (max-width: $mobile) {
  .uc {
    max-height: fit-content;
    min-height: fit-content;
    height: fit-content;

    .over-home--svg {
      transform: translate(-55%, -50%) rotate(-90deg) scale(2);
    }

    .home {
      video {
        &.visible {
          transform: translate(-50%, -50%) scale(1.2);
        }
      }

      .home-content {
        padding: 0 30px;
        gap: 50px;

        .uc-text {
          margin-top: 0;

          .text {
            h1 {
              font: normal normal 900 52px/62px Akrobat;
              letter-spacing: -0.72px;
              &:nth-of-type(2) {
                font: normal normal 100 48px/38px Akrobat;
              }
            }
            span {
              font: normal normal 300 26px/32px Akrobat;
              letter-spacing: -0.33px;
            }
          }
          img {
            padding-top: 40px;
            width: 100%;
            max-width: 300px;
          }
        }
        footer {
          gap: 10px;

          span {
            font: normal normal 300 16px/32px Akrobat;
            letter-spacing: -0.2px;
            opacity: 0.5;
          }
          .links {
            flex-direction: column;
            gap: 20px;
            a {
              section {
                p {
                  font: normal normal bold 20px/25px Oswald;
                }
                span {
                  font: normal normal 300 18px/20px Oswald;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>