<template>
  <main>
    <HomeFirst />
    <AboutUs />
    <Projects />
    <Services />
    <NewsView v-if="$i18n.locale === 'bg'"/>
    <!-- <ServiceAndMaintenance /> -->
    <Achievements />
    <!-- <International /> -->
    <Contacts />
    <Careers />
    <Green />
  </main>
</template>

<script>
import HomeFirst from "./home/HomeFirst.vue";
import Services from "./home/Services.vue";
import Projects from "./home/Projects.vue";
import Achievements from "./home/Achievements.vue";
// import International from "./home/International.vue";
// import ServiceAndMaintenance from "./home/ServiceAndMaintenance.vue";
import AboutUs from "./home/AboutUs.vue";
import Contacts from "./home/Contacts.vue";
import NewsView from "./home/NewsView.vue";
import Careers from "./home/Careers.vue";
import Green from "./home/Green.vue";
export default {
  name: "HomeView",
  components: {
    HomeFirst,
    Services,
    Projects,
    Achievements,
    // International,
    AboutUs,
    Contacts,
    // ServiceAndMaintenance,
    Green,
    Careers,
    NewsView,
  },
};
</script>
<style scoped lang="scss">
main {
  overflow: hidden;
  scroll-snap-type: y proximity;

  > * {
    scroll-snap-align: start;
  }
}
</style>
