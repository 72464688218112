<template>
  <transition name="modal" appear mode="out-in">
    <main
      class="modal-wrapper"
      :class="{ 'modal-wrapper--mobileMode': isMobileMode }"
    >
      <div class="modal">
        <img
          @click="close"
          v-if="isMobileMode"
          class="close"
          src="@/assets/img/close.svg"
          alt=""
        />
        <div class="form-wrapper">
          <form>
            <Input
              type="text"
              :placeholder="$t('form.name')"
              :disabled="loading"
              required="true"
              v-model="form.name"
            />
            <Input
              type="email"
              :id="'email'"
              :placeholder="$t('form.email')"
              :disabled="loading"
              required="true"
              v-model="form.email"
            />
            <Input
              type="phone"
              id="phone"
              :inputmode="true"
              :placeholder="$t('form.phone')"
              :disabled="loading"
              required="true"
              v-model="form.phone"
            />
            <Input
              type="text"
              :placeholder="$t('form.service')"
              :disabled="loading"
              required="true"
              :list="options"
              :specialFirst="true"
              v-model="form.service"
              readonly="readonly"
            />
            <Input
              style="width: 100%"
              type="text"
              :placeholder="$t('form.message')"
              :disabled="loading"
              required="true"
              :maxlength="200"
              v-model="form.message"
            />
          </form>
          <Button
            @click="submit"
            type="white"
            :mainText="$t('buttons.contacts.main')"
            :span="$t('buttons.contacts.span')"
          />
        </div>
      </div>
    </main>
  </transition>
</template>
<script>
import axios from 'axios';
import Button from "@/components/Button";
import Input from "@/components/Input";
export default {
  name: "FormModal",
  props: ["isMobileMode"],
  components: {
    Button,
    Input,
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        email: "",
        phone: "",
        service: this.$t("services.contacts-dd")[0],
        message: "",
      },
      options: this.$t("services.contacts-dd"),
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    isValid() {
      if (!this.form.name) {
        this.$snotify.warning(this.$t('snotify.warnings.name'));
        return false;
      } else if (!this.form.email || !this.validateEmail(this.form.email)) {
        this.$snotify.warning(this.$t('snotify.warnings.email'));
        return false;
      } else if (
        !this.form.phone ||
        !this.validatePhoneNumber(this.form.phone)
      ) {
        this.$snotify.warning(this.$t('snotify.warnings.phone'));
        return false;
      } else if (!this.form.service) {
        this.$snotify.warning(this.$t('snotify.warnings.service'));
        return false;
      } else if (!this.form.message) {
        this.$snotify.warning(this.$t('snotify.warnings.message'));
        return false;
      }

      return true;
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhoneNumber(phone) {
      const re = /^[0-9 ]+$/;
      return re.test(String(phone).toLowerCase());
    },
    submit() {
      if (this.isValid()) {
        this.loading = true;

        axios.post(`${process.env.VUE_APP_BASE_URI}/email/inquiry`, this.form)
          .catch((err) => {
            console.log(err);
          }).then(() => {
            this.form = {
              name: "",
              email: "",
              phone: "",
              service: "",
              message: "",
            };
            setTimeout(() => {
              this.loading = false;
              this.$snotify.success(this.$t('snotify.done.form'));
            }, 1000)
          });
      }
    },
  },
  mounted() {
    if (this.isMobileMode) document.body.classList.add("noscroll");
     this.form.service = this.$t("services.contacts-dd")[0];
  },
  destroyed() {
    document.body.classList.remove("noscroll");
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/base.scss";
.modal-wrapper {
  &.modal-wrapper--mobileMode {
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.749);
    display: flex;
    justify-content: center;
    left: 0;
    height: 100%;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    .modal {
      align-items: center;
      display: flex;
      color: #373737;
      height: 100%;
      justify-content: center;
      position: relative;
      width: 100%;

      .form-wrapper {
        &::before {
          background-color: $mainColor;
          clip-path: polygon(
            85% 0,
            100% 15%,
            100% 100%,
            15% 100%,
            0 85%,
            0% 0%
          );
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        align-items: center;
        // border-radius: 10px;
        padding: 10px 10px 0;
        width: 100%;

        form {
          flex-direction: column;
          width: 90%;

          .input-wrapper {
            width: calc(100% - 5px);
          }
        }

        .button {
          margin: -30px 0 0;
          transform: translateY(20px);
        }
      }
    }
  }
}

.modal {
  .close {
    cursor: pointer;
    background: $mainColor;
    display: block;
    border-radius: 50%;
    padding: 5px;
    max-width: 60px;
    // margin-top: -30px;
    max-height: 60px;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 992;
    @include hover();
  }
  .form-wrapper {
    display: flex;
    gap: 3rem;
    flex-direction: column;
    // transform: translateX(15%);
    position: relative;
    z-index: 1;
    form {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 10px;
      max-width: 750px;

      .input-wrapper {
        width: calc(50% - 5px);
      }
    }

    button {
      align-self: center;
    }
  }
}
@media all and (max-width: $mobile) {
}
</style>
